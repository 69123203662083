.products-container {
    padding: 20px 50px; 
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    justify-content: center;
    grid-gap: 20px;
    /* padding: 20px 0px;  */
    padding: 10px;
    margin: 0 auto;
  }

.product-card {
    color: black;
    /* border: 1px solid #888;  */
    border-radius: 8px; 
    transition: transform 450ms;
    box-shadow: 0 10px 20px -10px #EFF6FF;
}

.product-card:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.product-link {
    display: flex; 
    flex-direction: column;
    align-items: center; 
    text-decoration: none; 
    color: #888;
}

.product-image {
    width: 200px; 
    height: 300px; 
    border-radius: 8px; 
    align-items: top; 
    object-fit: cover; /* This will ensure the image covers the area, may crop */
}

.product-details {
    padding: 10px 0; 
    display: flex; 
    flex-direction: column; 
    font-size: 15px; 
}

.product-item-link {
    text-decoration: none;
    color: #555; 
}

.product-item-link:hover {
    text-decoration: none;
    color: #555; 
    transform: scale(1.2); 
}

.product-top-container {
    display: flex; 
    flex-direction: row; 
    justify-content: center;
    align-items: top;
}

.product-details {
    display: flex; 
    flex-direction: column; 
    text-align: left; 
    padding: 0px 10px; 
}

.product-title-cn {
    padding: 5px 0px;
    font-weight: 500;
    text-align: center;
}

.product-title-en {
    /* padding: 5px 0px;  */
    font-weight: 500;
    text-align: center;
}

.product-link {
    text-decoration: none;
}


.product-actions {
    display: flex;
    flex-direction: row;
    width: 100%; 
    /* justify-content: flex-start; */
    gap: 20px;
    /* Adjust the space between buttons as needed */
    padding: 10px 0;
    /* This will space out the buttons evenly */
    justify-content: space-around;
    align-items: center;
}

.btn-product-action {
    margin: 10px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px; 
    white-space: nowrap;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
}

.btn-product-action:hover, .btn-product-action.active {
    color: black;
    background-color: rgba(208, 236, 246);
}

.product-action-icon {
    margin-left: 5px;
}

.disclosure-container {
    padding-top: 200px; 
}