/* AppNavbar.css */
/* !important ensures override of Bootstrap's default styles */
/* Set the background color of the navbar to white */

.navbar {
    /* Make navbar fixed to the top of the page */
    position: sticky;
    /* Align it to the top */
    top: 0;
    /* Make it span the full width */
    width: 100%;
    /* Ensure it stays on top of other content */
    z-index: 1000;
    background-color: white !important;
    /* background-color: rgb(159, 145, 145) !important;  */
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: stretch; */
    justify-content: space-between;
    font-size: 17px;
    font-weight: 500;
    padding: 0 !important;
}

.container {
    /* padding: 20px 50px 10px 50px !important; */
    padding: 10px 50px !important;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.navbar-left {
    flex: 0 0 10%;
    /* display: flex;  */
    /* justify-content: flex-start; */

}

.navbar-collapse-wrapper {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.navbar-items {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.navbar-center {
    order: 1; 
    flex: 0 0 70%;
    display: flex;
    justify-content: center;
}

.navbar-right {
    order: 2;
    flex: 0 0 20%;
    display: flex;
    justify-content: flex-end;
}

.close-dropdown {
    order: 3;
}


/* Remove the border from the collapsed burger button */
.navbar-toggler {
    border: none;
}

/* Remove the focus border/outline from the burger button (optional) */
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

/* Align navbar items to the right */
.navbar-collapse {
    /* justify-content: flex-end; */
    justify-content: space-evenly;
    display: flex;
    /* position: static; */
    width: 100%;
}

/* Additional styles to ensure navbar items are aligned correctly */
.navbar-nav {
    display: flex;
    align-items: center;
    padding: none !important;
    /* justify-content: space-around;  */
}

/* Style adjustments for the navbar links, if needed */
.nav-link {
    /* margin-right: 1rem; */
    /* Adjust space between links */
    padding: none !important;
}

.icon-box {
    text-align: center;
}

/* .navbar-item-box {
    display: flex; 
    flex-direction: column; 
} */

.navbar-brand {
    display: flex;
    flex-direction: column;
    /* flex-direction: row;  */
    align-items: center;
    text-align: center;
    /* color: black; */
    /* push the brand to the left and nav items to the right */
    font-size: 18px;
    transition: transform 0.3s ease;
}

.navbar-brand:hover {
    transform: scale(1.1);
}

/* Style the navbar-nav to distribute space evenly between nav items when navbar is not expanded */
.navbar:not(.expanded) .navbar-nav {
    /* Ensure it fills the space between brand and profile icon */
    flex-grow: 1;
    /* Evenly space the nav items */
    justify-content: space-around;
    /* justify-content: end; */
}

.navbar-nav .nav-item {
    /* Add padding to each nav item for spacing */
    padding: 0 10px;
}

.navbar-center {
    display: flex;
    justify-content: center;
    gap: 0.1rem;
}

/* .navbar-right {
} */

.navbar-item {
    display: flex;
    flex-direction: column;
    /* flex-direction: row;  */
    /* color: black; */
    transition: transform 0.3s ease;
}

.navbar-item:hover {
    transform: scale(1.1);
}

.navbar-item-text {
    width: 100%;
    white-space: nowrap;
    /* margin-right: 5px; */
}

.navbar-text-gradient-1 {
    background: linear-gradient(to right,
            rgba(41, 177, 250, 1) 0%,
            rgba(47, 210, 198, 1) 33.33%,
            rgba(248, 0, 178, 1) 66.67%,
            rgba(246, 165, 33, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.navbar-text-gradient-2 {
    background: linear-gradient(to right,
            rgba(115, 45, 246, 1) 0%,
            rgba(246, 88, 43, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;

}

.navbar-text-gradient-1,
.navbar-text-gradient-2 {
    font-weight: 600;
    font-size: 18px;
    transition: transform 0.3s ease;
}

.navbar-text-gradient-1:hover,
.navbar-text-gradient-2:hover {
    transform: scale(1.1);
}

.navbar-profile-photo {
    width: 50px; 
    border-radius: 50px; 
}

.navbar-profile-icon {
    font-size: 2rem;
}

.nav-dropdown {
    right: 0 !important;
    /* Align to the right side of the navbar */
    left: auto !important;
    /* Override default left property */
    width: auto !important;
    /* Only as wide as its content */
    transition: transform 0.3s ease;
}

/* .nav-dropdown-item {
    display: flex; 
    flex-direction: row; 
} */

/* More dropdown */
.nav-dropdown .dropdown-toggle::after {
    display: none;
    /* Disables the default arrow */
    transition: transform 0.3s ease;
}

.nav-dropdown:hover > .dropdown-menu {
    display: block; 
    margin-top: 0; 
    transition: transform 0.3s ease;
}

.dropdown-menu {
    display: none; 
    transition: transform 0.3s ease;
}

.navbar .dropdown:hover .dropdown-menu {
    display: block;
    transition: transform 0.3s ease;
}

.dropdown-title .dropdown-icon {
    display: inline-block;
    /* Ensures the arrow is part of the flow and positioned correctly */
    margin-left: 8px;
    /* Adjust spacing to your liking */
    transition: transform 0.2s ease;
}

.dropdown-title {
    display: flex;
    align-items: center;
    /* Aligns the text and icon vertically */
    justify-content: center;
    /* justify-content: space-between; */
    cursor: pointer;
    /* Indicates this is a clickable dropdown */
}

.dropdown-texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Ensures text alignment is consistent */
}

.dropdown-icon,
.navbar-dropdown-icon {
    display: flex;
    align-items: center;
    /* Center the icon with respect to the texts */
    font-size: 2rem;
    /* Adjust size as needed */
}

.navbar-dropdown-icon {
    margin-left: 5px;
    /* Spacing between texts and the icon */
}

.navbar-dropdown-icon:hover {
    cursor: pointer;
}

.auth-links {
    display: flex;
    /* flex-direction: row; */
    /* align-items: center; */
}

/* #profile-dropdown {

} */

/* Style for the close button inside the dropdown */
.close-dropdown {
    display: flex;
    flex-direction: row;
    padding: 8px;
    /* Add some padding */
    /* Add a separator */
    /* border-top: 1px solid #eee; */
    /* Light background for the close area  */
    /* background-color: #f8f9fa; */
    align-items: center;
    /* justify-content: center; */
    justify-content: end;

}

.close-dropdown:hover {
    color: black;
    font-weight: 600;
}

.btn-close {
    display: none;
}

.btn-close:hover {
    background-color: transparent;
    color: black;
    font-weight: 600;
    /* Visible color */
    cursor: pointer;
    /* Pointer cursor on hover */
    font-weight: 600;
}

/* Button to close the dropdown */
.btn-close-dropdown {
    display: none;
    /* Center button within the close-dropdown div */
    /* margin: 10px auto;
    padding: 5px 10px; */
    border: none;
    background: transparent;
    /* background-color: #F8F9FA; 
    border-radius: 25px;  */
    color: #333;
    cursor: pointer;
}

.btn-close-dropdown:hover {
    border: none;
    background-color: transparent;
    color: #333;
    /* Visible color */
    cursor: pointer;
    /* Pointer cursor on hover */
}


/* Adjusted styles to show the button when navbar is expanded */
.navbar.expanded .btn-close,
.navbar.expanded .btn-close-dropdown {
    /* display: block; */
    display: inline-block;
}

/* Searchbar */

.searchbar-container {
    width: 100%;
    max-width: 500px;
    /* padding: 0 !important; */
    padding: 3px 0 !important;
}

.searchbar-form {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 25px;
    border: 1px solid #ddd;
    /* padding: 5px 10px; */
    padding: 3px 10px;
    transition: transform 450ms;
}

.searchbar-form:hover {
    border: 1px solid #555;
    transform: scale(1.08);
    /* box-shadow: 0 10px 20px -10px #555; */
}

.search-input {
    /* width: 90%; */
    /* Input should grow to fill the space */
    flex-grow: 1;
    border: none;
    font-size: 18px;
    padding: 3px 5px;
}

.search-input:hover,
.search-input:focus {
    outline: none;
}

.btn-searchbar, .btn-clear {
    min-width: 40px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    /* background-color: none !important; */
    display: flex;
    /* Center items vertically */
    align-items: center;
    /* Center items horizontally */
    justify-content: center;
    padding: 5px;
}

.btn-searchbar:hover, .btn-clear:hover {
    cursor: pointer;
    padding: 5px;
    background-color: #F8F9FA;
}

.search-icon {
    font-size: 1.5rem;
    color: #555;
}

.clear-icon {
    font-size: 1.5rem;
    color: #555;
}

.clear-icon:hover {
    cursor: pointer;
    background-color: #F8F9FA;
}


/* searchbar dropdown  */

.search-dropdown {
    border-radius: 25px;
    border: 1px solid #ddd;
    padding: 5px 10px;
}

.search-dropdown-item {
    display: flex;
    flex-direction: row; 
    align-items: center; 
    padding: 5px 10px; 
    transition: transform 450ms;
    /* border-radius: 8px;  */
    border-radius: 25px;
}

.search-dropdown-item:hover {
    cursor: pointer; 
    transform: scale(1.02);
    background-color: #F8F9FA;
}

.search-dropdown-image {
    width: 60px; 
    height: 90px; 
    object-fit: cover;
    border-radius: 8px; 
}

.search-dropdown-details {
    text-align: left; 
    font-size: 13.5px; 
    padding-left: 10px; 
}

.navbar-banner {
    width: 100%;
    color: grey;
    /* padding: 10px 50px; */
    /* padding: 10px 20px 5px 20px; */
    padding: 0px 20px !important;
    display: inline-block;
}


@media (max-width: 1200px) {
    .container {
        /* margin: 0 30px !important;  */
        margin: 0 !important;
        /* padding: 16px 32px !important; */
        padding: 10px 32px !important;
        align-items: center;
    }

    .navbar {
        /* width: 100%;  */
        /* justify-content: space-between; */
        padding: 0 !important;
        display: flex;
        justify-content: center;
    }

    .navbar-collapse {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding: 5px; */
    }

    .navbar-items {
        width: 100%;
        justify-content: flex-start;
    }

    .navbar-right, .navbar-center {
        justify-content: flex-start;
    }

    .navbar-center {
        order: 1; 
    }

    .navbar-right {
        order: 0; 
        margin-left: 0; 
    }

    .navbar-nav {
        display: flex;
        flex-direction: column;
        /* center the items */
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        padding: 5px;
        max-width: 100%;
    }

    /* Full width */
    /* Center the items */
    .navbar-collapse ul {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    /* Don’t grow or shrink */
    .navbar-collapse ul li {
        flex: 0 0 auto;
    }

    .navbar-item,
    .dropdown-texts {
        display: flex;
        flex-direction: row;
        color: black;
    }

    .dropdown-title {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .dropdown-texts {
        align-items: center;
    }

    /* .navbar-brand {
        justify-content: flex-start; 
        margin-right: 10px; 
    } */

    .navbar-item-text {
        width: 100%;
        white-space: nowrap;
        margin-right: 5px;
    }

    .dropdown-icon,
    .navbar-dropdown-icon {
        margin: 0 !important;
        /* Spacing between texts and the icon */
    }

    .navbar-dropdown-icon,
    navbar-profile-icon {
        font-size: 30px !important;
    }

    .navbar:not(.expanded) .navbar-nav {
        flex-direction: row;
        /* Keep the nav items in a row */
        /* Distribute space evenly */
        /* justify-content: space-around; */
        /* Space items out evenly */
        /* justify-content: space-between; */
    }

    .close-dropdown {
        justify-content: flex-end;
        /* Align close button to the right */
        padding: 0;
    }

    .btn-close,
    .btn-close-dropdown {
        display: block;
        /* Show the close buttons */
    }

    .searchbar-container {
        padding: 3px 0 !important; 
    }
}

/* @media (max-width: 768px) {

} */

@media (max-width: 450px) {


    .navbar-collapse {
        padding: 0;
    }

    .close-dropdown {
        padding: 0;
    }

    .searchbar-container {
        /* padding: 10px 20px !important; */
        padding: 5px 20px !important;
    }

    .navbar-banner {
        width: 100%;
        /* padding: 10px 20px 5px 20px; */
        padding: 0px 20px !important;
        display: inline-block;
    }
}

@media (max-with: 150px) {

    .container {
        /* margin: 0 30px !important;  */
        margin: 0 !important;
        /* padding: 16px 32px !important; */
        padding: 10px 20px !important;
        align-items: center;
    }

    .navbar {
        margin: 0 !important;
        /* padding: 16px 32px; */
        padding: 10px 20px;
        align-items: center;
        justify-content: space-between;
    }

    .container {
        display: flex;
        align-items: center;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
    }
}