/* Auth Page */

.auth-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;
    padding: 30px;
    align-items: center;
}

.auth-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px 50px 50px 50px;
    padding: 0px 50px;
    background-color: white;
}

/* Each child takes up equal space */
/* Add some space around elements */
.branding,
.auth-form {
    flex: 1;
    margin: 10px;
}

.branding {
    flex-basis: 50%;
    color: #333;
}

.auth-promo {
    flex-basis: 50%;
    background-color: #fff;
    /* padding: 20px; */
    /* padding: 40px; */
}

.auth-img {
    width: 90%;
    /* object-fit: contain;  */
    object-fit: fill;
    border-radius: 10px; 
}

.auth-form-container {
    flex-basis: 50%;
    background-color: #fff;
    padding: 10px 20px 40px 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.1); */
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-form {
    background-color: #fff;
    /* padding: 40px; */
    width: 90%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.1); */
    /* display: flex; 
    flex-direction: column;  */
    align-items: center;
}


.auth-form h2 {
    margin-bottom: 10px;
}

.auth-form p {
    margin-bottom: 10px;
    font-size: 16px;
}

.form-group.password-group {
    position: relative;
}

.password-container {
    display: flex;
    align-items: center;
}

.form-control {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 10px 15px !important;
}

.form-input {
    border: none !important;
    padding-bottom: none;
}

.form-password-icon {
    cursor: pointer;
    position: absolute;
    right: 20px;
    /* Adjust the value as needed */
    top: 50%;
    transform: translateY(-50%);
    padding-top: 20px;
}

.auth-form button {
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    align-items: center;
}

.auth-form button:hover {
    color: white;
}

.auth-company {
    white-space: nowrap;
}

.btn-auth {
    background-color: #444;
    align-items: center;
    border-radius: 25px !important;
    padding: 10px 15px !important;
}

/* .btn-auth:hover {
    background-color: #444;
    align-items: center;
} */

.btn-oauth {
    background-color: white !important;
    color: black !important;
    border: 1px solid black !important;
    border-radius: 25px !important;
    padding: 10px 15px !important;
}

.btn-oath:hover {
    background-color: #F8F9FA !important;
    color: #F8F9FA !important;
}

.auth-spinner-text {
    margin-left: 10px; 
}

.auth-icon {
    color: black;
    margin-right: 10px;
    cursor: pointer;
}

.auth-form small {
    display: block;
    margin-top: 10px;
    color: #666;
}

.auth-form small a {
    color: #0077cc;
}

.auth-message {
    margin-bottom: 0px !important; 
    padding-top: 10px;
    font-size: 16px;
}

.auth-link {
    text-decoration: none;
    font-weight: 600; 
    /* color: grey; */
    color: #0D6EFD;
    /* font-size: 18px;  */
}

.auth-link:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.auth-term {
    margin-top: 10px;
}

/* Media query for smaller screens */
@media (max-width: 992px) {
    .auth-container {
        flex-direction: column;
        /* Stack children vertically */
        margin: 0 0 50px 0;
        padding: 20px;
    }

    .auth-form-container {
        width: 100%;
        /* Each child takes full width */
        align-items: center;
        justify-content: center;
        margin-bottom: 50px;
        /* Add space between elements */
    }

    .branding,
    .auth-promo,
    .auth-form {
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        /* Add space between elements */
    }

    .auth-img {
        max-width: 90%;
        object-fit: fill;
    }

    .auth-form {
        padding: 20px;
    }

    .btn-auth {
        margin-top: 20px; 
    }

    .footer {
        width: 100%;
        /* Footer takes the full width of the screen */
        padding: 20px;
        /* Add padding to the footer */
        text-align: center;
        /* Center footer content */
        bottom: 0;
        /* Stick to the bottom */
    }
}