/* Page.css */

.page-wrapper {
    /* margin-top: 75px;  */
    /* padding: 0rem 2.5rem;  */
    /* padding: 16px 32px;  */
    padding: 20px 30px;
    /* padding-top: 60px; */
    /* height: 100%;  */
    align-items: center;
}

.main {
    padding: 0rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.page-header {
    /* padding: 0rem 2.5rem;  */
    /* text-align: left;  */
    text-align: center;
}

.page-list {
    padding: 10px 20px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
}

.list-container {
    /* margin: 20px 30px;  */
    padding: 16px 32px;
    align-items: left;
    justify-content: left;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 20px 0px;
}

.list-link {
    text-decoration: none;
    color: black;
}

.list-link:hover {
    cursor: pointer;
    color: black;
}

.list-header {
    font-size: 23.5px;
    margin: 10px;
    display: flex;
    justify-content: left;
}

.list-header:hover {
    cursor: pointer;
    font-size: 25px;
}

.titles-list {
    list-style: none;
    display: flex;
    flex-direction: row;
}

/* title item & title card: */

.list-item {
    margin: 10px 5px;
    padding: 10px;
    display: flex;
    align-items: top;
    text-decoration: none;
    color: black;
}

.list-item-link {
    text-decoration: none;
    color: black;
}

.list-item-card {
    display: flex;
    flex-direction: column;
    transition: transform 450ms;
}

.list-item-card:hover {
    cursor: pointer;
    transform: scale(1.08);
}

.item-image-container {
    width: 200px;
}

.item-image {
    width: 200px;
    height: 300px;
    /* min-width: 200px;  */
    max-width: 100%;
    /* height: auto;  */
    /* max-height: 100%;  */
    /* height: auto;  */
    /* object-fit: scale; */
    object-fit: cover;
    border-radius: 5px;
}

.item-info {
    display: flex;
    flex-direction: column;
    width: 200px;
}

.item-title-cn {
    padding: 5px 0px;
    font-weight: 500;
    text-align: center;
}

.item-title-en {
    /* padding: 5px 0px;  */
    font-weight: 500;
    text-align: center;
}


/* Title Page */


.title-page-container {
    padding: 0px 35px;
}

.title-page-header {
    padding: 0px 0px 30px 20px;
}

.title-page {
    display: flex;
    flex-direction: row;
    /* padding: 20px 50px;  */
    padding: 2rem 5rem;
    align-items: center;
    justify-content: center;
}

.title-page-left {
    flex: 0.8
}

.title-page-right {
    flex: 0.2
}

.title-top-container {
    display: flex;
    flex-direction: row;
    /* align-items: center;  */
    /* Align items to the top */
    align-items: flex-start;
    justify-content: center;
    max-width: 100%;
    vertical-align: top;
}

.title-image-container {
    width: 250px;
}

.title-page-image {
    width: 300px;
    height: auto;
    max-width: 100%;
    /* width: 400px; 
    height: 600px;  
    max-width: 100%; 
    object-fit: cover;  */
    border-radius: 10px;
}

.title-details {
    padding: 0px 30px;

}

.title-details-item {
    display: flex;
    flex-direction: row;
    /* padding: 5px 0px; */
    padding-bottom: 5px;
}

.title-item-title {
    color: black;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
}

.title-item-label {
    color: #666;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}

.title-item-text {
    color: black;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    vertical-align: center;
    padding-left: 10px;
    text-align: left;
}

/* .title-rating-label {
    display: inline-block;
    cursor: pointer;
    padding: 0 2px;
} */

.title-ratings-item {
    display: flex; 
    flex-direction: row; 
    text-align: left;
}

.title-ratings-info {
    display: flex; 
    justify-content: center; 
    text-align: left; 
}

.title-ratings-score {
    margin-right: 3px; 
}

.title-ratings-count {
    color: grey;
    margin-right: 5px; 
}

.rating-star-rating {
    align-items: center;
    position: relative;
}

.title-mid-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.tabbed-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* justify-content: flex-start; */
    gap: 20px;
    /* Adjust the space between buttons as needed */
    padding: 10px 0;
    /* This will space out the buttons evenly */
}

.btn-title-action {
    margin: 10px;
    border: none;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 15px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btn-title-action:hover,
.btn-title-action.active {
    color: black;
    background-color: rgba(208, 236, 246);
}

.title-action-icon {
    margin-left: 5px;
}

.title-trailer-container {
    width: 100%;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title-video-responsive {
    width: 100%;
    /* display: flex;
    flex-direction: column; */
    /* Aligns children (title and episode info) to the start of the cross axis */
    /* align-items: center; */
}

/* 
.title-video-responsive {
    position: relative;
    padding-bottom: 56.25%; 
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    background: #000;
} */

/* .title-video-responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
} */

.title-embed-video {
    /* width: 80%;  */
    border-radius: 10px;
}

/* Watchlink */

.watch-section,
.watchlink-container {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.watchlink-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title-details-container {
    width: 70%;
    padding-top: 10px;
}

.title-link-container,
.episode-container {
    display: flex;
    flex-direction: row;
    color: #333;
}

.title-link-container {
    text-decoration: none;
    transform-origin: left center;
    /* This will animate the scaling */
    transition: transform 0.3s ease;
}

.title-link-container:hover {
    transform: scale(1.02);

}

.title-link-container:visited {
    text-decoration: none;
}

.watchlink-title,
.watchlink-episode {
    margin-right: 10px;
    padding: 5px 0;
    background: none;
    display: in-line;
}

.watchlink-episode {
    color: #555;
}


.watchlink-actions {
    width: 70%;
    display: flex;
    justify-content: right;
}

.report-link {
    text-decoration: none;
}

.btn-report {
    padding: 5px 15px;
    border-radius: 25px;
    color: black;
    background-color: #F8F9FA;
    font-size: 13px;
}

.btn-report:hover {
    border-radius: 20px;
    color: white;
    background-color: #888 !important;
    font-weight: 600;
}

.container-header,
.container-text {
    text-align: left;
}

/* Filter & Sort */

.filter-section-text {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0 20px;
    padding: 10px 0px;
    font-size: 15px;
    color: grey;
}

.filter-sort-container {
    display: flex;
    flex-direction: column;
    background-color: #F8F9FA;
    margin: 0 20px;
    /* padding: 20px;  */
    padding: 15px 20px 10px 20px;
    border-radius: 10px;
}

/* Allow items to wrap as needed */
/* Align items vertically */
/* Space between each section */
.filter-section,
.sort-section {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}

/* Space between the header and the buttons */
/* Prevent the header text from wrapping */
/* Prevent the header from shrinking */
/* Default width of the header */
/* Don't allow the header to grow */


.filter-header,
.sort-header,
.btn-filter,
.btn-sort {
    padding: 2px 10px;
    /* margin: 5px 5px 5px 0; */
    /* margin: 3px 3px 3px 0; */
    margin: 2px 2px 2px 0;
    /* font-size: 15px;  */
    font-size: 13.5px;
}

.filter-header,
.sort-header {
    flex: 0.1;
    display: flex;
    text-align: left;
    flex-direction: column;
    /* white-space: nowrap;  */
}

.filter-genre,
.filter-dynasty {
    color: grey;
}

.filter-selection,
.sort-selection {
    flex: 0.9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    /* Align buttons to the start */
    /* justify-content: flex-start;  */
    /* Ensure there is a small gap between buttons */
    gap: 5px;
}

/* Ensure text within buttons does not wrap */
.btn-filter,
.btn-sort {
    /* display: flex; 
    flex-direction: column; */
    align-items: center;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.btn-filter:hover,
.btn-sort:hover {
    border: 1px solid #F8F9FA;
    background-color: #F8F9FA;
}

.btn-filter-active,
.btn-sort-active {
    font-weight: bold;
}

.selected-summary {
    display: flex;
    text-align: left;
    margin: 0 20px;
    /* padding: 10px 20px;  */
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 600;
}

.star-rating:hover {
    cursor: pointer;
}

.btn-episodes {
    font-size: 13.5px;
    margin-right: 10px;
}

.error-container {
    margin: 2rem auto;
    padding: 0 1.5rem;
    max-width: var(--content-width, 1200px);
  }
  
  .error-message {
    margin-bottom: 1.5rem;
  }
  
  .error-cn, .error-en {
    margin: 0;
    line-height: 1.5;
  }
  
  .error-cn {
    margin-bottom: 0.5rem;
  }
  
  .error-image {
    max-width: 300px;
    height: auto;
    display: block;
    margin: 0 auto;
  }

/* Reviews */
.review-avatar {
    font-size: 50px;
    width: 3rem;
    border-radius: 25px;
}

/* Terms & Privacy */

.terms-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terms-content-container {
    padding: 20px 50px;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.terms-header {
    font-size: 16px;
    margin: 10px 0;
    /* text-align: left; */
}

.terms-text {
    font-size: 15px;
    text-align: left;
}


@media (max-width: 992px) {

    .footer {
        width: 100%;
        padding: 20px;
        text-align: center;
        bottom: 0;
    }

    .title-embed-video {
        width: 600px;
        height: 338px;
    }
}

@media (max-width: 768px) {

    /* On smaller screens, allow more wrapping and adjust button padding */
    .filter-selection {
        /* Align to start if they wrap */
        justify-content: start;
    }

    .btn-filter {
        /* Adjust padding on smaller screens */
        /* padding: 5px; */
        white-space: wrap;
        text-align: left;
    }

    .footer {
        width: 100%;
        /* Footer takes the full width of the screen */
        padding: 20px;
        /* Add padding to the footer */
        text-align: center;
        /* Center footer content */
        bottom: 0;
        /* Stick to the bottom */
        background-color: #f8f9fa;
        /* Footer background color */
    }

    .title-page-container {
        padding: 0px;
    }

    /* Adjusts max-width for smaller screens */

    .title-trailer-container {
        padding: 0px;
    }

    .title-embed-video {
        width: 500px;
        height: 280px;
    }

    .title-details-container {
        /* Smaller padding for smaller screens */
        padding: 5px;
        /* Smaller font size for smaller screens */
        font-size: 90%;
    }

}

@media (max-width: 650px) {
    .title-ratings-item {
        flex-direction: column; 
        text-align: left; 
    }

    .rating-star-icon {
        font-size: 18px; 
    }
}

@media (max-width: 600px) {
    .title-page-image {
        width: 220px;
    }

    .title-embed-video {
        width: 500px;
        height: 280px;
    }
}

/* Responsive adjustments for screens smaller than 450px */
@media (max-width: 500px) {

    .title-page-image {
        width: 200px;
    }

    .title-details {
        padding-left: 20px;
        padding-right: 0px;
    }

    .title-item-title {
        font-size: 16px;
    }

    .title-item-label,
    .title-item-text {
        font-size: 14.5px;
    }

    .title-actions {
        /* Stack buttons vertically */
        /* display: grid;
        grid-template-columns: 1fr 1fr; */
        /* flex-direction: column;   */
        /* Reduce gap */
        gap: 2px;
        padding: 10px 0;
        width: 100%;
    }

    .btn-title-action {
        font-size: 14px;
        /* Reduce font size */
        margin: 5px;
        /* Reduce margin */
    }

    .title-action-icon {
        margin-left: 3px;
        /* Adjust icon margin */
    }

    .title-embed-video {
        width: 400px;
        height: 225px;
    }
}

@media (max-width: 450px) {
    
    .page-wrapper {
        padding: 20px;
    }

    .filter-section,
    .sort-section {
        display: flex;
        flex-direction: column;
    }

    .filter-header, .sort-header {
        font-size: 14px; 
        padding-bottom: 10px; 
        display: flex; 
        text-align: center; 
    }

    .filter-selection, .sort-selection {
        display: flex; 
        /* justify-content: center; */
    }

    /* .filter-sort-container, .selected-summary {
        margin: 0; 
    } */

    .titles-grid {
        padding: 10px 0; 
    }

    .title-page-image {
        width: 180px;
    }

    .title-embed-video {
        width: 350px;
        height: 197px;
    }

}

@media (max-width: 400px) {

    .title-page-image {
        width: 160px;
    }

    .title-embed-video {
        width: 300px;
        height: 169px;
    }

}

@media (max-width: 300px) {

    .page-wrapper {
        display: flex;
        flex-direction: column; 
        align-items: center;
        justify-content: center;
    }

    .titles-grid {
        display: grid; 
        grid-gap: 10px; 
        padding: 10px 0; 
    }

    .title-top-container {
        flex-direction: column;
    }

    .title-page-image {
        width: 150px;
    }

    .title-details {
        padding: 10px 0 0 20px;
    }

    .btn-title-action {
        padding: 8px;
        margin: 3px;
        font-size: 13px;
    }

    .title-embed-video {
        width: 250px;
        height: 140px;
    }
}