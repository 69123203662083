.forum-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; 
}

.forum-header {
    background-color: hsl(0, 0%, 98%);
    width: 100%;
    padding: 20px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.forum-main {
    display: flex;
    flex-direction: row; 
    justify-content: center;
    width: 80%;
    max-width: 1200px;
    margin: 20px auto;
    gap: 20px;
}

.sidebar-container {
    width: 200px; 
    padding: 20px 10px; 
    border-right: 1px solid #ddd;
    text-align: left; 
    /* justify-content: top; */
}

.sidebar-link {
    text-decoration: none; 
    display: block; 
    padding: 10px; 
    transition: transform 450ms;
}

.sidebar-link:hover {
    cursor: pointer; 
    border-radius: 4px; 
    transform: scale(1.05);
}

.forum-container {
    flex: 1; 
    max-width: 100%; 
}

.tabs {
    display: flex;
    gap: 10px;
    width: 100%; 
    margin: 20px 0 !important;
}

.tab {
    padding: 5px 20px;
    border: none;
    border-radius: 20px;
    /* background-color: #F8F9FA; */
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab.active {
    /* background-color: #007bff; */
    background-color: rgb(208, 236, 246) !important;
}

.tab:hover {
    background-color: rgb(208, 236, 246) !important;
    /* background-color: #F8F9FA; */
    cursor: pointer;
    transform: scale(1.05);
}

.btn-forum, .btn-create-post {
    border: none;
    border-radius: 25px;
    font-weight: 500;
    background-color: #f0f0f0 !important;
    /* background-color: #F8F9FA; */
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn-forum:hover, .btn-create-post:hover {
    background-color: rgb(208, 236, 246) !important;
    color: #333;     
    cursor: pointer;
    transform: scale(1.05);
}

.btn-post-group {
    display: flex; 
    flex-direction: row; 
    max-width: 100%; 
}

.btn-post {
    padding: 10px 20px;
    margin: 0px 20px; 
    width: 100%; 
    font-size: 1rem; 
    border: none;
    border-radius: 20px !important;
    transition: background-color 0.3s;
}

.btn-post:hover {
    cursor: pointer;
    font-weight: 600;
    transform: scale(1.05);
}
.btn-create-submit {
    background-color: #333 !important;
    color: white !important; 
    font-weight: 600;
}

.btn-create-submit:hover {
    color: white !important; 
}

.btn-create-cancel {
    background-color: #F8F9FA !important;
    color: #333 !important; 
    font-weight: 600;
}

.post-form-container {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%; 
}

.post-form-container form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post-form-container input,
.post-form-container textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
}

.error {
    color: red;
    margin-top: 10px;
}

.posts-container {
    max-width: 100%; 
    display: flex; 
    flex-direction: column; 
}

.posts-table {
    max-width: 100%; 
    border-collapse: collapse;
}

.posts-table-header {
    border-radius: 20px; 
}

.posts-table th,
.posts-table td {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.posts-table th {
    background-color: #f0f0f0;
}

.post-row:hover {
    background-color: #f9f9f9;
}

.post-actions {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: right;
    font-size: 12px; 
}

.post-action {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 20px;
    background-color: #f0f0f0;
    cursor: pointer;
}

.post-action:hover {
    background-color: #e9ecef;
}


.post-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: left; 
}


