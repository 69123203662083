.star-rating-container {
    display: inline-flex;
    align-items: center;
    position: relative;
}

.star-label {
    position: relative;
    cursor: pointer;
}

.star-input {
    display: none;
}

.star-fill {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.star-icon {
    color: #e4e5e9;
    transition: color 200ms;
}

.star-icon.filled {
    color: #ffc107;
}

.star-icon.hovered {
    color: #ffc107;
}

.star-rating-container:hover .star-icon:not(.hovered):not(.filled) {
    color: #c1c3c5;
}

.rating-hover {
    margin-left: 10px;
    font-size: 14px;
}

.rating-popup {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #f0f0f0;
    padding: 5px;
    border-radius: 3px;
    font-size: 14px;
    margin-top: 5px;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}