

/* Common Styles */

.carousel-container {
    position: relative;
    margin-top: 30px; 
    /* padding-bottom: 50px;  */
    padding-bottom: 60px; 
}

.slide-track {
    display: flex !important; 
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    padding: 0 10px !important; 
}

.slide-title {
    text-align: center;
    font-size: 25px !important;
    font-weight: 600; 
    /* margin-bottom: 20px; */
    margin-bottom: 30px;
    color: #333;
}

.slide-title:hover {
    cursor: pointer;
    transform: scale(1.05);
}

.btn-carousel-pause {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    cursor: pointer;
}

/* Hide the default Slick arrows */
.slick-prev:before,
.slick-next:before {
    content: none; /* Remove the default arrows */
}

.carousel-arrow-icon {
    font-size: 3rem;
}

.custom-arrow {
    /* position: absolute; */
    /* top: 50%; */
    /* transform: translateY(-50%); */
    z-index: 1;
    cursor: pointer;
    /* background-color: rgba(0, 0, 0, 0.5); */
    color: rgba(0, 0, 0, 0.5) !important;
    border-radius: 50%;
    /* padding: 10px; */
    /* font-size: 2rem !important; */
    display: flex; 
    align-items: center; 
    justify-content: center; 
}

/* Move the left arrow further to the left */

.custom-arrow-left {
    /* left: -70px;  */
    /* margin-left: 10px;  */
    left: -35px; 
}

/* Move the right arrow further to the right */

.custom-arrow-right {
    /* right: -20px;  */
    /* margin-right: 10px;  */
    right: -10px; 
}

.slick-dots {
    /* position: absolute;  */
    /* bottom: -35px; */
    bottom: 20px;
    left: 50%; 
    transform: translateX(-50%);
    display: flex !important; 
    justify-content: center;
    align-items: center; 
    margin: 0; 
    padding: 0; 
    list-style: none;
    font-size: 1rem;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}

.slick-dots li button:before {
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
}

/* Common Styles */
.video-container {
    /* display: flex !important; 
    flex-direction: column !important; 
    justify-content: center !important; */
    position: relative;
    width: auto;
    margin: 0 20px; 
    /* 16:9 aspect ratio */
    /* max-width: 100%;  */
    padding-bottom: 56.25%; 
    /* padding-bottom: 100%;  */
    /* height: 0; */
    /* border-radius: 12px; */
    overflow: hidden;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    /* border: none;  */
    /* box-shadow: none; */
}


/* Styles for images in inner sliders */

.slide {
    /* display: flex !important;
    flex-direction: column !important; 
    align-items: center !important;
    justify-content: center !important; */
    /* Make sure this matches the height of your container or adjust as needed */
    /* height: 450px;  */
    border: none; 
    box-shadow: none;
    /* overflow: hidden; */
}

.slide img, .section-link img {
    border: none; 
    box-shadow: none;
}

.ai-image-slide {
    padding: 0 30px; 
    border-radius: 12px !important; 

}

.ai-image {
    width: 100%; 
    height: auto; 
    border-radius: 12px !important; 
}

.products-slide .slick-slide {
    margin: 0 !important;
    padding: 0 30px !important; 
    width: auto !important;
    /* width: auto;  */
    display: flex !important;
    flex-direction: column !important; 
    justify-content: center !important; 
    align-items: center !important;
}

.product-card-slide, .event-card-slide {
    width: 300px !important; 
    /* width: 250px !important;  */
    /* margin: 0 !important;  */
    margin: 0 10px !important; 
    padding: 0 !important; 
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    text-align: center;
    color: #333;
    font-weight: 600; 
}

/* .product-card-slide:hover {
    transform: scale(1.05);
} */

.product-card-slide img {
    width: 300px;
    height: 450px;
    /* width: 250px; */
    /* height: 350px; */
    /* width: 100%; 
    height: auto; 
    max-width: 300px; 
    max-height: 400px;  */
    object-fit: cover;
    border-radius: 12px !important; 

}

.product-card-slide h5 {
    margin-top: 5px;
    font-size: 15px;
    /* max-width: 300px;  */
    max-width: 250px;
    /* max-width: 200px;   */
}


/* Responsive Styles */
@media (max-width: 1200px) {
    .slick-dots {
        bottom: 30px; 
    }

    .slide-title {
        font-size: 22px;
        margin-bottom: 30px;
    }

    .product-card img, .event-card img {
        /* max-width: 250px; */
        width: 250px; 
        height: 350px; 
        /* width: 220px; 
        height: 320px;  */
    }
}

@media (max-width: 992px) {
    .slick-dots {
        bottom: 15px; 
    }
    .slide-title {
        font-size: 20px;
    }

    .product-card img, .event-card img {
        /* max-width: 200px; */
        width: 200px; 
        height: 300px;
    }
}

@media (max-width: 768px) {
    .slick-dots {
        bottom: 10px; 
    }

    .slide-title {
        font-size: 18px;
    }

    .product-card img, .event-card img {
        width: 180px; 
        height: 270px;
    }

    .product-card h5 {
        font-size: 12px;
    }
}

@media (max-width: 480px) {
    .carousel-arrow-icon {
        font-size: 2.5rem;
    }

    .slide-title {
        font-size: 16px;
    }
    
    .product-card img {
        width: 150px;
        height: 200px;
    }
    
    .product-card h5 {
        font-size: 10px;
    }
}

@media (max-width: 350px) {
    .product-card img, .event-card img {
        max-height: 150px;
    }

}
