.checkout-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
}

.checkout-content {
    display: flex;
    flex-direction: row;
    max-width: 800px;
    width: 100%;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan-details {
    flex: 1;
    padding: 20px;
    border-right: 1px solid #e0e0e0;
}

.plan-details h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.plan-details p {
    font-size: 16px;
    margin: 5px 0;
}

.plan-details .price {
    font-size: 32px;
    color: #32325d;
    margin: 20px 0;
}

.payment-form {
    flex: 1;
    padding: 20px;
}

.payment-form form {
    display: flex;
    flex-direction: column;
}

.payment-form h2 {
    margin-bottom: 10px;
}

.payment-form label {
    margin-bottom: 10px;
}

.payment-form input {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
}

.payment-form .card-element {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    margin-bottom: 20px;
}

.payment-form button {
    background-color: #6772e5;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.payment-form button:disabled {
    background-color: #bbb;
    cursor: not-allowed;
}
