/* src/css/Breadcrumbs.css */

.breadcrumb {
    font-size: 1rem;
  }
  
  .breadcrumb-item {
    display: flex;
    align-items: center;
  }
  
  /* Tablet */
  @media (max-width: 992px) {
    .breadcrumb {
      font-size: 0.9rem;
    }
    
    .breadcrumb .mx-2 {
      margin: 0 0.3rem !important;
    }
  }
  
  /* Mobile */
  @media (max-width: 450px) {
    .breadcrumb {
      font-size: 0.9rem;
    }

    /* .breadcrumb-dropdown .dropdown-toggle::after {
        display: none;
      } */
      
    .breadcrumb-item {
        padding-left: 0 !important; 
      }
    
    .breadcrumb .mx-1 {
        margin: 0 !important;
    }

    .breadcrumb .mx-2 {
      margin: 0 !important;
    }
    
    .me-1 {
      margin-right: 0.1rem !important;
    }
  }