.product-details {
    display: flex; 
    padding: 10px; 
    /* justify-content: center;  */
}

.btn-shop-action {
    border-radius: 25px; 
    font-size: 1.35rem; 
    font-weight: 500; 
    /* background-color: ;  */
    margin: 10px;
    padding: 5px 15px; 
    border: none;
    white-space: nowrap;
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    color: black;
    background-color: rgba(208, 236, 246);

}

.btn-shop-action:hover, .btn-shop-action.active {
    color: black;
    background-color: rgb(190, 247, 238);
    transform: scale(1.08);
}