.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab {
    font-size: 18px; 
    /* padding: 10px 20px; */
    padding: 10px 0;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    transition: all 0.3s ease;
}

.tab.active {
    border-color: #000;
    /* or your active tab indicator color */
    font-weight: bold;
    outline: none; 
}


/* Style active term */
.term-content, .term {
    /* when term details should be visible */
    /* display: block;  */
    padding: 0px 20px; 
    /* margin: 0 auto;  */
    /* min-width: 200px;*/
    justify-content: center;
    text-align: center; 
    /* align-items: center; */
    /* background-color: white; */
    border-radius: 8px; 
}

.term {
    display: flex; 
    align-items: center;
}

.term-container {
    display: flex; 
    flex-direction: row; 
    align-items: center; /* Center content */
    justify-content: center;
    /* Center text */
    /* text-align: center;  */
    margin: 20px; /* Add some margin */
    width: 80%; /* Use full width */

}

.terms {
    display: flex;
    /* flex-direction: row; */
    flex-direction: row; /* Stack children vertically */
    justify-content: center; /* Aligns horizontally center in the container */
    align-items: center; /* Aligns vertically center in the container */
    /* width: 100%; */
    height: 100%;
}


.term-left, .term-right {
    width: 100%; 
}

.term-left {
    margin: 20px;
}

.term-right {
    margin: 0px 20px; 
    max-width: 800px; 
}

/* .term-icon {
    width: 100px;
    height: 100px; 
    object-fit: cover;
    border-radius: 50%; 
    margin-bottom: 10px; 
} */

.term-image {
    width: 100%; 
    /* min-width: 100px; */
    max-width: 350px;
    height: auto; 
    object-fit: cover;
    border-radius: 8px; 
    margin: 20px 0; 
}

.term-term {
    font-weight: 600;
}

.term ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.term ul li {
    /* font-size: 1rem;  */
    font-size: 17px;
    padding-bottom: 8px;
}


.learn-list {
    list-style: none;
    margin: 2rem 5rem;
}

.learn-list-item {
    background-color: #F8F9FA;
    align-items: left;
    margin: 10px 0px;
    padding: 10px;
}

.learn-text-card {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.learn-text-type {
    margin-bottom: 10px;
}

.learn-text-source {
    text-align: center;
}


.btn-term {
    /* color: white;
    background-color:#444; */
    color: black;
    background-color: white !important;
       /* background-color: #F8F9FA;  */
    /* background-color: rgba(212, 210, 253); */
    border: none;
    border-radius: 25px;
    /* padding: 0.5rem 1.25rem; */
    margin: 10px;
    padding: 10px 25px;
    font-size: 18px; 
    font-weight: 600;
    cursor: pointer;
    transition: 450ms;
}

.btn-term:hover {
    color: black;
    /* background-color: rgb(208, 236, 246) !important; */
    background-color: rgba(212, 210, 253) !important;
    /* background-color: #F8F9FA;  */
    /* opacity: 0.8; */
}

@media (max-width: 768px) {

    .section-container {
      /* Flex direction column will stack children vertically */
      flex-direction: column; 
      text-align: center;
    }
  
    .term-container {
      /* Set flex-direction to column to stack term-left and term-right on top of each other */
      flex-direction: column;
      align-items: center; /* This will center align the child elements */
    }
  
    .term-left,
    .term-right {
      /* Set both term-left and term-right to take full width */
      width: 100%;
      max-width: 100%; /* This will prevent them from overflowing their container */
      margin: 0; /* Adjust margin to keep layout tidy */
    }
  
    .term-image {
      /* You can adjust max-width to control the image size */
      max-width: 90%; /* For example, images take up to 90% of their container's width */
      height: auto; /* Keep height auto to maintain aspect ratio */
      margin: 0 auto; /* Auto margins will center the image */
    }
  
    .term {
      /* Remove fixed min-width to allow terms to be responsive */
      min-width: 0;
      max-width: 100%;
    }
  
    .term-def {
      /* Optional: Add a breakpoint for font size if needed to keep text from becoming too small */
      font-size: 14px;
    }
  
    .tabs {
      /* Flex-wrap wrap will allow tabs to wrap to the next line if they don't fit */
      flex-wrap: wrap;
      justify-content: center; /* Center tabs */
    }
    
    .tab {
      /* Set tabs to be full width to stack them vertically, or set a percentage width to fit multiple tabs in a row */
      width: 100%; /* 100% width will stack them */
      margin-bottom: 10px; /* Add margin for space between stacked tabs */
    }
  
    .btn-term {
      /* Update button styling as needed for mobile view */
      padding: 8px 16px; /* Adjust padding */
      font-size: 16px; /* Adjust font size */
    }
  }