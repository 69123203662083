.trendinglist {
  padding: 0px 10px; 
}

.titles-list-container {
    display: flex;
    align-items: center;
  }
  
  .titles-list {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden; 
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  
  .titles-list::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }
  
  .scroll-button {
    /* border: none; */
    /* margin: 5px; */
  }

  .scroll-icon {
    color: #555;
    font-size: 2.75rem;
    padding: 8px; 
    border-radius: 50%;
    background-color:  #F8F9FA;
  }

  .scroll-icon:hover {
    cursor: pointer; 
    transform: scale(1.2);
  }

  .header-text {
    padding: 0px 10px;
    font-size: 14.5px;
    color: grey;
  }

  .title-card {
    flex: 0 0 auto; /* Don't grow, don't shrink, auto basis */
    margin-right: 16px; /* Adjust spacing between cards */
  }
  
  @media (max-width: 450px) {

    .trendinglist {
      padding: 0; 
    }
    
  }