/* ContactForm.css */
.contact-form {
    /* display: grid; */
    /* place-items: center; */
    display: flex; 
    flex-direction: column;
    align-items: center;    
    /* Additional centering for horizontal */
    margin: 0 auto;   
    padding: 20px 0; 
    width: 100%; /* Make the form width responsive */
    max-width: 500px; /* Set the maximum width of the form */
  }
  
  .contact-form .form-group {
    width: 100%; /* Ensure form groups take full width of the form */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .contact-form .form-label {
    text-align: left;
    margin-bottom: 0.25rem; 
    font-weight: 600; 
  }

  .contact-form .form-control {
    margin-bottom: 0.1rem;
  }

  .contact-form .borderless-input {
    border: none;
    border-bottom: 1px solid #ced4da; /* Bottom border only */
    border-radius: 0; /* Removes the default border-radius */
    box-shadow: none; /* Removes box shadow if any */
    width: 100%; /* Make input fields responsive */
    padding-left: 0; /* Align text in input with label */
  }
  
  .contact-form .borderless-input:focus {
    border-color: grey;
    outline: 0; /* Removes the default focus outline */
    box-shadow: none; /* Removes box shadow on focus */
  }

  /* .contact-form textarea {

  } */
  
  /* Align label to the top */
  .align-top {
    vertical-align: top;
  }
  
  .btn-contact {
    margin-top: 10px; 
    border-radius: 20px; 
  }
  
  .btn-contact:hover {
    cursor: pointer; 
    font-weight: bold; 
  }


  /* Report page */

  .report-header {
    margin: 20px 0px; 
  }

  .report-container {
    padding: 30px 0; 
  }