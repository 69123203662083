/* TitleGrid.css */



/* This will create exactly 5 columns regardless of the screen width */
/* grid-template-columns: repeat(5, 1fr); */
/* Adjust minmax() as needed */
.titles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 20px;
  /* padding: 20px 0px;  */
  padding: 10px;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
}

/* .titles-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
} */

@media (max-width: 450px) {
  .titles-grid {
    margin: auto;
    max-width: 200px;
  }
}

@media (max-width: 300px) {
  .titles-grid {
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

/* Larger cards on wider screens */
/* @media (min-width: 600px) {
    .titles-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    }
  } */

/* Even larger cards on wider screens */
/* @media (min-width: 900px) {
    .titles-grid {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
    }
  } */

.list-header-link {
  text-decoration: none;
  padding: 10px;
  font-size: 1.5rem;
  text-align: left;
  color: black;
}
