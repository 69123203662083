/* General page styles */
.page-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    /* padding: 20px; */
    /* background: #f0f0f0; light grey background for the whole page */
}

.page-banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(256, 166, 166);
    /* background-color: rgb(253, 239, 247); */
    /* background-color: rgb(253, 250, 239); */
    /* background: linear-gradient(to left, rgba(41, 180, 220) 0%, rgba(41, 180, 220, 0.1) 100%); */
    /* background-color: rgb(245, 250, 253); */
    /* background-color: rgb(208, 236, 246); */
    /* background: linear-gradient(to right, rgba(208, 236, 246, 1) 0%, rgba(208, 236, 246, 0.1) 100%); */
    /* background: linear-gradient(to left, rgba(208, 236, 246, 1) 0%, rgba(208, 236, 246, 0.1) 100%); */
    /* background: linear-gradient(to left, rgba(254, 206, 230, 1) 0%, rgba(254, 206, 230, 0.1) 80%); */
    padding: 10px 0px;
    font-weight: 600;
    transition: transform 450ms;
}

.page-banner:hover {
    transform: scale(1.08);
}

.banner-text {
    margin: 0px 20px;
    text-align: center;
    font-size: 16px;
    text-decoration: none;
    color: #444;
}

.banner-text:visited {
    color: #444;
}

.section-container {
    width: 100%;
    padding: 50px 0px;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0,0,0,0.1); */
}

.section-container-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section-container-1,
.section-container-2,
.section-container-3 {
    display: flex;
    flex-direction: row;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0,0,0,0.1); */
}

.section-container-4,
.section-container-5,
.section-container-6,
.section-container-7,
.section-container-8 {
    display: flex;
    flex-direction: column;
}

.section-container-0 {
    background: linear-gradient(to right, rgba(208, 236, 246, 1) 0%, rgba(208, 236, 246, 0.1) 100%);
    /* min-height: 800px; */
}

.section-container-1 {
    background: linear-gradient(to left, rgba(190, 247, 238, 1) 0%, rgba(190, 247, 238, 0.1) 100%);
    /* min-height: 600px; */
}

.section-container-2 {
    background: linear-gradient(to right, rgba(254, 206, 230, 1) 0%, rgba(254, 206, 230, 0.1) 80%);
    /* overflow: hidden;  */
    min-height: 800px;
}

.section-container-3 {
    background: linear-gradient(to left, rgba(251, 238, 198, 1) 0%, rgba(251, 238, 198, 0.1) 80%);
}

.section-container-4 {
    padding: 50px 0px;
    background: linear-gradient(to right, rgba(212, 210, 253, 1) 0%, rgba(212, 210, 253, 0.1) 80%);
}

.section-container-5 {
    background: linear-gradient(to left, rgba(208, 236, 246, 1) 0%, rgba(208, 236, 246, 0.1) 80%);
}

/* .section-container-6 {
    background: linear-gradient(to right, rgba(201, 246, 185, 1) 0%, rgba(201, 246, 185, 0.1) 100%);
    background: linear-gradient(to right, rgba(190, 247, 238, 1) 0%, rgba(190, 247, 238, 0.1) 100%);
} */

.section-container-7 {
    background: linear-gradient(to left, rgba(252, 219, 205, 1) 0%, rgba(252, 219, 205, 0.1) 100%);
    /* background: linear-gradient(to right, rgba(190, 247, 238, 1) 0%, rgba(190, 247, 238, 0.1) 100%); */
    /* background: linear-gradient(to left, rgba(222, 183, 142, 1) 0%, rgba((222, 183, 142, 0.1) 80%); */
}

/* .section-container-8 {
    border-radius: 8px;
    background: linear-gradient(to right, rgba(190, 247, 238, 1) 0%, rgba(190, 247, 238, 0.1) 100%);
    background: linear-gradient(to left, rgba(222, 183, 142, 1) 0%, rgba((222, 183, 142, 0.1) 80%);
}
   */

.section-landing {
    width: 90%;
    display: flex; 
    flex-direction: column; 
}

.section-landing-intro {
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 600px;
}

.section-landing-header {
    display: flex; 
    flex-direction: column;
    padding: 30px 0 20px 0; 
}

.section-header-gradient-1 {
    font-weight: 700 !important;
    /* font-size: 30px !important;  */
    background: linear-gradient(to right,
    rgba(41, 177, 250, 1) 0%,
    rgba(47, 210, 198, 1) 33.33%,
    rgba(248, 0, 178, 1) 66.67%,
    rgba(246, 165, 33, 1) 100%);
background-clip: text;
-webkit-background-clip: text;
color: transparent;
-webkit-text-fill-color: transparent;
display: inline-block;
}

.section-landing-text {
    padding: 30px 80px !important;
}

.section-landing-buttons {
    margin: 20px 0;
}

.btn-landing-0 {
    margin: 10px 20px !important;
    font-size: 1.2rem;
    font-weight: 600;
    /* background-color: #333;
    color: white;  */
    background-color: rgb(208, 236, 246);
    color: #333; 
    transition: transform 450ms;
}

.btn-landing-0:hover {
    cursor: pointer;
    font-weight: 600;
    /* background-color: rgb(208, 236, 246);
    color: black; */
    background-color: #333 !important;
    color: white; 
    transform: scale(1.08);
}

.section-header {
    margin-bottom: 1rem;
}

.section-text {
    flex: 1;
    /* Prevent text from taking more than half the container's width */
    max-width: 50%;
    padding: 0px 20px;
    /* Ensures text doesn't touch images */
}

.section-image {
    flex: 1;
    text-align: center;
    /* Center-align the image */
    /* padding: 10px; */
    object-fit: fill;
}

.landing-text-cn,
.landing-text-en {
    display: flex;
    flex-direction: column;
    margin: 10px 0; 
    font-size: 25px;
}

.landing-text-cn {
    font-weight: 500;
}

.landing-text-en {
    font-weight: 600;
}

.horizontal-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btn-landing {
    border-radius: 50px;
    padding: 10px 30px;
    border: none;
    margin: 10px 0px 20px 0px;
    transition: transform 450ms;
}

.btn-landing:hover {
    background-color: rgb(208, 236, 246);
    /* background-color: #FFFFFF; */
    border: none;
    transform: scale(1.08);
}

.section-link {
    text-decoration: none;
}

.section-1-link {
    display: flex;
    flex-direction: row;
    padding: 20px;
}

.section-2-link {
    padding: 20px;
}

.section-3-link {
    padding: 20px;
}

.landing-image {
    /* height: auto; */
    border-radius: 8px;
    /* Rounded corners for images */
    transition: transform 450ms;
}

.landing-image:hover {
    transform: scale(1.08);
}

.landing-1-image {
    min-width: 100px;
    max-width: 300px;
    min-height: 150px;
    max-height: 450px;
    margin: 0px 5px;
    object-fit: cover;
}

.landing-2-image {
    min-width: 200px;
    max-width: 400px;
    min-height: 200px;
    max-height: 400px;
    margin: 0px 5px;
    object-fit: cover;
}

.landing-3-image {
    min-width: 100px;
    max-width: 300px;
    min-height: 150px;
    max-height: 450px;
    margin: 0px 5px;
    object-fit: cover;
}

.rotate-image-container {
    position: relative;
    /* Set the container size if necessary */
    /* This will expand the container based on the size of the images */
    /* width: max-content;  */
    display: flex;
    /* flex-wrap: nowrap; */
    flex-direction: column;
    /* Center the images in the container */
    /* justify-content: center;  */
    /* Align images vertically */
    align-items: center;
    gap: 20px;
}

.rotated-image {
    position: absolute;
    transition: transform 0.5s;
    /* smooth transition for the rotation */
}

.top-image {
    transform: rotate(-8deg);
    /* Rotate the first image -10 degrees */
    left: 150px;
    /* Adjust the position to overlap */
    /* top: 10px; Adjust the position to overlap */
    bottom: -20px;
    z-index: 1;
}

.bottom-image {
    transform: rotate(10deg);
    /* Rotate the second image 10 degrees */
    right: 10px;
    /* Adjust the position to overlap */
    /* Adjust the position to overlap */
    /* bottom: 5px;  */
}

/* Media query to maintain the angle regardless of screen size */
@media screen and (max-width: 1000px) {
    .top-image {
        transform: rotate(-10deg);
        left: 0px;
    }

    .bottom-image {
        transform: rotate(10deg);
    }
}

.btn-landing-1 {
    background-color: rgb(190, 247, 238);
}

.btn-landing-2 {
    background-color: rgb(254, 206, 230);
}

.btn-landing-3 {
    background-color: rgb(251, 238, 198);
}

.btn-landing-4 {
    background-color: rgb(212, 210, 253);
}

/* .btn-landing-5 {
    background-color: rgb(212, 210, 253);
} */

/* H2 styles for section titles */
h2 {
    margin: none !important;
    font-weight: 600;
    color: #444;
}

/* Paragraph styles */
/* p {
    font-size: 16px;
    color: #444;
    margin: none !important; 
    padding: none !important;
} */

.section-text-line {
    font-size: 16px;
    color: #444;
    padding: none !important;
}


.partners {
    margin: 50px 40px;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    /* Centers the items horizontally */
    align-items: center;
    /* Centers the items vertically */
    flex-wrap: wrap;
    /* Allows items to wrap to the next line as needed */
    gap: 20px;
    /* Space between items */
}

.partner {
    flex: 1;
    /* Each item will grow to take equal space */
    min-width: 150px;
    /* Minimum width of each item */
    max-width: 250px;
    /* Maximum width of each item */
    text-align: center;
    /* Centers the image within the icon div */
}

.partner img {
    width: 100%;
    /* Makes the image fill the container */
    height: auto;
    /* Maintains the aspect ratio of the image */
    object-fit: contain;
    /* Ensures the image is fully visible, contained within its aspect ratio */
}

.qr-code-container {
    margin: 20px;
}

.qrcode-img {
    width: 300px;
}


.qrcode-img:hover {
    cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 1200px) {

    .section-image-1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .horizontal-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-image-2 {
        display: flex;
        flex-direction: row;
        padding-left: 100px;
        /* height: 100%;  */
    }

    .rotate-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }

    .top-image {
        transform: rotate(0deg);
        /* Rotate the first image -10 degrees */
        left: 0;
    }

    .bottom-image {
        transform: rotate(0deg);
        /* Rotate the first image -10 degrees */
        left: 0;
    }

    .landing-1-image {
        width: 90%;
        /* Adjust width to a percentage of the container */
        max-width: 300px;
        /* Maximum width to constrain the images */
        height: auto;
        /* Maintain aspect ratio */
    }
}


@media (max-width: 992px) {
    .section-landing {
        width: 90%;
    }

    .section-text {
        width: 70%;
    }

    .section-image {
        display: flex;
        flex-direction: row;
    }

    .landing-image {
        /* Set a minimum width for medium screens */
        min-width: 250px;
        /* Set a minimum height based on a 3:2 aspect ratio */
        min-height: 375px;
    }

    .horizontal-image-container {
        display: flex;
        flex-direction: column;
    }

    .landing-1-image,
    .landing-2-image {
        width: 90%;
        /* Adjust width to a percentage of the container */
        max-width: 300px;
        /* Maximum width to constrain the images */
        height: auto;
        /* Maintain aspect ratio */
    }

    .rotate-image-container {
        display: flex;
        flex-direction: column;
    }

    .top-image {
        transform: rotate(0deg);
        /* Rotate the first image -10 degrees */

    }

    .bottom-image {
        transform: rotate(0deg);
        /* Rotate the first image -10 degrees */
        left: 0;
    }
}


@media (max-width: 768px) {
    .section-container {
        /* Stack sections vertically on smaller screens */
        display: flex;
        flex-direction: column;
    }

    .section-text,
    .section-image {
        padding: 10px;
        text-align: center;
    }

    .section-text {
        order: 2;
        /* Text comes after the image on small screens */
        /* Prevent text from taking more than half the container's width */
        max-width: 80%;
        padding: 0px 10px;
        /* Ensures text doesn't touch images */

    }

    .section-image {
        order: 1;
    }

    .landing-image {
        max-width: 100%;
        /* Full width on smaller screens */
    }

    .rotate-image-container {
        flex-direction: column;
        /* Ensure images stack on smaller screens */
    }

    .rotated-image {
        position: relative;
        /* Change position to relative for stacking */
    }

    .top-image,
    .bottom-image {
        transform: none;
        /* No rotation for smaller screens */
    }

    /* Adjust image size for smaller screens */
    .landing-2-image {
        width: 90%;
        /* Adjust width to a percentage of the container */
        max-width: 350px;
        /* Maximum width to constrain the images */
        height: auto;
        /* Maintain aspect ratio */
    }

}


@media (max-width: 350px) {

    .section-landing {
        width: 90%;
    }

    rotate-image-container {
        position: fixed;
        display: flex;
        flex-direction: column;
    }

    .top-image {
        transform: rotate(0deg);
        /* Rotate the first image -10 degrees */
        /* top: 10px; Adjust the position to overlap */
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }

    .bottom-image {
        transform: rotate(0deg);
        /* Rotate the second image 10 degrees */
        /* Adjust the position to overlap */
        /* bottom: 5px;  */
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
    }

}