.pricing-container {
    width: 100%;
    padding: 30px 0px; 
    text-align: center;
  }
  
  .pricing-header {
    margin-bottom: 1rem;
  }
  
  .toggle-buttons {
    margin-bottom: 2rem;
  }
  
  .toggle-buttons button {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .toggle-buttons button.active {
    border-bottom: 2px solid black;
  }
  
  .plans {
    display: flex;
    justify-content: center;
    /* gap: 1rem; */
    gap: 20px;
  }
  
  .plan {
    flex: 1; 
    min-width: 200px; /* Minimum width of each plan */
    max-width: 400px; /* Maximum width, adjust as needed
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    margin: 0px 10px; 
    /* padding: 1rem; */
    padding: 20px;
    transition: 450ms;
    /* box-shadow: 0 2px 8px #ccc; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .plan:hover {
    cursor: pointer; 
    transform: scale(1.08);
  }
  
  .plan-icon {
    font-size: 2rem;
  }

  .plan h3 {
    margin-top: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .plan h4 {
    margin: 0.5rem 0;
    /* font-size: 1.1rem; */
  }

  .plan-pricing {
    font-size: 1rem;
    font-weight: 700;
    padding: 5px 10px;
    border: none;
    border-radius: 8px; 
    background-color: #F8F9FA; 
  }
  
  .savings {
    color: red;
    font-weight: bold;
    padding-bottom: none !important;
  }

  .plan-texts {
    display: flex;
    flex-direction: column;
  }
  .plan-text {
    text-align: left; 
  }
  
  .plan ul {
    list-style: none;
    /* padding: 0; */
    padding: 5px;
    text-align: left;
  }
  
  .plan ul li {
    margin: 0.5rem 0;
    /* font-size: 1rem;  */
    font-size: 15px; 
  }
  
  .plan ul li::before {
    /* content: '✓'; */
    content: '✔️';
    color: black;
    margin-right: 0.5rem;
  }
  
  /* .plan button {
    color: white;
    background-color:#444;
    border: none;
    border-radius: 25px; 
    padding: 0.5rem 1.25rem;
    font-weight: 500; 
    cursor: pointer;
    transition: 450ms;
  }
  
  .plan button:hover {
    color: black; 
    background-color: rgb(208, 236, 246);
    opacity: 0.8;
  } */

  .btn-pricing {
    color: white;
    background-color:#444;
    border: none;
    border-radius: 25px; 
    padding: 0.5rem 1.25rem;
    font-size: 18px; 
    font-weight: 600; 
    cursor: pointer;
    transition: 450ms;
  }
  
  .btn-pricing:hover {
    color: black; 
    /* background-color: rgb(208, 236, 246); */
    background-color: #F8F9FA; 
    opacity: 0.8;
  }
  
  
  .btn-free {
    /* color: white;
    background-color:#444; */
    color: black; 
    /* background-color: #F8F9FA;  */
    background-color: rgb(208, 236, 246);
    border: none;
    border-radius: 25px; 
    padding: 0.5rem 1.25rem;
    font-weight: 600; 
    cursor: pointer;
    transition: 450ms;
  }
  
  .btn-free:hover {
    color: black; 
    /* background-color: rgb(208, 236, 246); */
    background-color: #F8F9FA; 
    /* background-color: rgb(208, 236, 246); */
    /* opacity: 0.8; */
  }
  
  /* Responsive adjustments */
@media (max-width: 768px) {
    .plans {
        flex-direction: column;
    }

    .plan {
        width: 80%; /* Full width on mobile */
        max-width: none; /* Override max width */
        margin: 10px auto; /* Center plans on the page */
    }
}

  /* Responsive adjustments */
  @media (max-width: 300px) {
    .plans {
        flex-direction: column;
    }

    .plan {
        width: 30%; /* Full width on mobile */
        max-width: none; /* Override max width */
        margin: 10px auto; /* Center plans on the page */
    }
}