
.title-card {
    /* Fixed width */
    width: 200px;
    /* Fixed height */
    height: 300px;
    margin: auto;
    /* margin: 10px; */
    /* padding: 10px; */
    /* border: 1px solid #ddd; */
    border-radius: 8px;
    /* text-align: center; */
    display: flex;
    flex-direction: column;
    /* Centers content on the cross axis */
    align-items: center;
    /* Aligns content to the top */
    justify-content: flex-start;
    /* Adjust content spacing within the card */
    /* justify-content: space-between;  */
    overflow: hidden;
    /* Ensures that content does not overflow the card */
    border-radius: 10px;
    /* Larger radius for rounded corners */
    /* Soft shadow for depth */
    /* box-shadow: 0 4px 8px rgba(0,0,0,0.1); */
    /* transition: transform 0.3s ease-in-out; Smooth transition for hover */
    transition: transform 450ms;
  
    /* newly added */
    position: relative;
  }
  
  .title-card:hover {
    /* transform: translateY(-5px); 
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);  */
    cursor: pointer;
    transform: scale(1.08);
  }
  
  .title-item-link {
    text-decoration: none;
    color: black;
  }
  
  /* newly added */
  .title-image-container {
    position: relative;
  }
  
  .title-image {
    /* Fill the width of the card */
    /* width: 100%;  */
    width: 180px;
    /* height: auto; */
    /* Fixed height for images */
    /* height: 200px; */
    height: 250px;
    /* Cover the area without losing aspect ratio */
    object-fit: cover;
    /* object-fit: contain;  */
    border-radius: 5px;
    /* newly added */
    transition: transform 0.3s ease;
  }
  
  /* newly added: */
  .title-hover-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Tinted cover */
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .title-card:hover .title-hover-overlay {
    opacity: 0.5;
    /* Show overlay on hover */
  }
  
  .title-play-icon {
    color: white;
    font-size: 2.5rem;
  }
  
  .title-rating {
    position: absolute;
    top: 2px;
    right: 10px;
    /* background-color: rgba(0, 0, 0, 0.2); */
    color: rgb(253, 184, 0);
    /* color: #ffc107;   */
    font-weight: bold;
    padding: 2px 5px;
    border-radius: 8px;
    font-size: 0.9rem;
    z-index: 1;  /* Ensure it's above the hover overlay */
  }

  .title-shop-icon {
    position: absolute;
    top: 5px;
    left: 20px;
    color: white;
    font-size: 1rem;
  }
  
  .title-name-en {
    font-size: 0.9rem;
  }
  
  .title-card-title {
    font-weight: 500;
  }
  
  .title-name,
  .title-episodes,
  .title-description {
    margin-top: 5px;
  }