.imageform-container {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}

.image-form {
    margin: 20px;
    padding: 20px 40px;
    /* flex-basis: 50%; */
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
    display: flex; 
    flex-direction: column;
}

.text-box {
    display: flex;
    flex-direction: column;
}

.label-text {
    text-align: left;
    display: block;
    color: black;
    font-weight: 500;
}

.form-text {
    padding: 0px 0px 5px 0px !important;
    font-style: italic;
    text-align: left;
}

.form-control {
    width: 100%;
    padding: 10px 15px !important;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    /* flex-grow: 1; */
    text-align: left;
}

.prompt-input {
    height: 100%; 
    /* resize: vertical; */
}

/* textarea #prompt.form-control {
    margin: none !important;
} */

.textarea-container {
    position: relative;
}

.custom-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px;
    /* Match your textarea's padding */
    pointer-events: none;
    /* Allows clicking through to the textarea */
    color: #aaa;
    /* Placeholder text color */
    font-family: inherit;
    /* Match your textarea's font styles */
    font-size: inherit;
    line-height: inherit;
    text-align: left;
    white-space: pre-wrap;
    /* Maintains white space and line breaks */
}

.textarea-container textarea {
    padding: 8px;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.textarea-container textarea:focus+.custom-placeholder,
.textarea-container textarea:not(:placeholder-shown)+.custom-placeholder {
    display: none;
}


.btn-generate {
    width: 100%;
    padding: 15px;
    margin: 10px 0px;
    border: none;
    border-radius: 25px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    align-items: center;
    font-size: 16px;
}

.btn-generate:hover {
    cursor: pointer;
    /* color: white;
    background-color: #333; */
    font-size: 18px;
    color: black;
    background-color: rgba(208, 236, 246);
}

.btn-generate:disabled {
    background-color: #333;
    color: #fff;
    font-size: 16px;
    font-weight: 600; 
}

.loading-texts {
    display: flex;
    flex-direction: column;
    font-size: 18px;
}

.spinner {
    margin: 20px 0px;
}

.generated-image {
    margin: 20px 0px;
}

.generated-image:hover {
    cursor: pointer;
}

.image-action-buttons {
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center; 
    vertical-align: center; 
}

.download-link {
    flex: 0.5; 
}

.share-link {
    flex: 0.5;
}

.btn-download {
    /* width: 100%; */
    width: 80%;
    padding: 15px;
    margin: 10px 20px;
    border: none;
    border-radius: 25px;
    color: black;
    background-color: rgba(208, 236, 246);
    align-items: center;
    font-size: 18px;
}

.btn-download:hover {
    cursor: pointer;
    /* color: white;
    background-color: #333; */
    color: #fff;
    background-color: #333;
    font-size: 20px;
}

.btn-download-icon {
    margin-left: 5px; 
    font-size: 18px; 
}

.social-share-buttons {
   display: flex;
   align-items: center;
   justify-content: space-around;
   vertical-align: center;
}


.social-share-icon {
    font-size: 2.5rem; 
    margin: 0px 5px; 
    transition: smooth;
    /* color: rgba(208, 236, 246);
    fill: rgba(208, 236, 246); */
    display: flex; 
    align-items: center;
}

.social-share-icon:hover {
    cursor: pointer; 
    transform: scale(1.2);
    /* color: rgba(208, 236, 246);  */
    color: black;
}

.social-share-copy {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
}

.icon-link { color: #888; border-radius: 25px; padding: 5px;}
.icon-twitter { color: #1DA1F2; border-radius: 25px; padding: 5px;} /* Twitter's brand color */
.icon-reddit { color: #FF4500; border-radius: 25px; padding: 5px;} /* Reddit's brand color */
.icon-pinterest { color: #E60023; border-radius: 25px; padding: 5px; } /* Pinterest's brand color */
.icon-instagram { color: #E1306C; border-radius: 25px; padding: 5px; } /* Pinterest's brand color */
.icon-facebook { color: #1877F2; border-radius: 25px; padding: 5px;} /* Facebook's brand color */
.icon-weibo { color: #E6162D; border-radius: 25px; padding: 5px;} /* Weibo's brand color */
.icon-whatsapp { color: #25D366; border-radius: 25px; padding: 5px;} /* WhatsApp's brand color */
.icon-email { color: #888; border-radius: 25px; padding: 5px;} /* Generic black for email */

.icon-link:hover { color: white; background-color: #888;}
.icon-twitter:hover { color: white; background-color :#1DA1F2}
.icon-reddit:hover { color: white; background-color: #FF4500; } /* Reddit's brand color */
.icon-pinterest:hover { color: white; background-color: #E60023; } /* Pinterest's brand color */
.icon-facebook:hover { color: white; background-color: #1877F2; } /* Facebook's brand color */
.icon-weibo:hover { color: white; background-color: #E6162D; } /* Weibo's brand color */
.icon-whatsapp:hover { color: white; background-color: #25D366; } /* WhatsApp's brand color */
.icon-email:hover { color: white; background-color: #888; } /* Generic black for email */


.icon-instagram {
    border-radius: 25px; 
    padding: 5px;
    /* Normal state with gradient fill */
    background: linear-gradient(45deg, #833AB4, #C13584, #E1306C, #FD1D1D, #F56040, #F77737, #FCAF45, #FFDC80);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    /* Smooth transition */
    transition: background 0.3s, color 0.3s;
}

.icon-instagram:hover {
    /* Hover state with white fill and gradient background */
    color: white;  /* White text fill */
    -webkit-text-fill-color: initial; /* Show the white color on hover */
    -webkit-background-clip: initial;
    background-clip: initial;
}


.share-text {
    font-size: 12px; 
    margin-left: 10px; 
    color: #555; 
}

.create-auth-link {
    text-decoration: none;
}

.create-image {
    max-width: 800px;
    margin: 10px;
}

.create-auth-link-1 {
    background: linear-gradient(to right,
            rgba(41, 177, 250, 1) 0%,
            rgba(47, 210, 198, 1) 33.33%,
            rgba(248, 0, 178, 1) 66.67%,
            rgba(246, 165, 33, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.create-auth-link-2 {
    background: linear-gradient(to right,
            rgba(115, 45, 246, 1) 0%,
            rgba(246, 88, 43, 1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;

}

.create-auth-link-1,
.create-auth-link-2 {
    transition: transform 0.3s ease;
}

.create-auth-link-1:hover,
.create-auth-link-2:hover {
    transform: scale(1.1);
}

.get-token-container {
    margin: 20px 0px; 
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    justify-content: center; 
}

.btn-get-token {
    padding: 10px 30px; 
    border: none; 
    border-radius: 50px; 
    background-color: rgba(208, 236, 246);
}

.btn-get-token:hover {
    transform: scale(1.3); 
    border: none; 
    background-image: linear-gradient(to right,
    rgba(208, 236, 246, 1) 0%,
    rgba(190, 247, 238, 1) 25%,
    rgba(254, 206, 230, 1) 50%,
    rgba(251, 238, 198, 1) 75%,
    rgba(212, 210, 253, 1) 87.5%,
    rgba(208, 236, 246, 1) 100%);
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);

}

.token-header {
    margin-bottom: none !important; 
}

.btn-buy-token {
    padding: 3px 10px; 
    border: none; 
    border-radius: 50px; 
    /* color: white; */
    color: black;
    background-image: linear-gradient(to right,
    rgba(208, 236, 246, 1) 0%,
    rgba(190, 247, 238, 1) 25%,
    rgba(254, 206, 230, 1) 50%,
    rgba(251, 238, 198, 1) 75%,
    rgba(212, 210, 253, 1) 87.5%,
    rgba(208, 236, 246, 1) 100%);
    box-shadow: 0 5px 10px rgba(0,0,0,0.1);
    font-weight: 600; 
}

.btn-buy-token:hover {
    transform: scale(1.1); 
    border: none; 
    /* color: black; 
    background-color: rgba(208, 236, 246); */
}


/* Gallery */

/* .gallery-container {   
} */

.gallery-grid, .image-grid {
    margin: 10px 0; 
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 10px;
    /* padding: 20px 0px;  */
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}

.gallery-grid {
    background-color: rgba(208, 236, 246);
}

.gallery-item {
    margin: 10px; 
    transition: transform 450ms;
}

.gallery-image {
    width: 350px; 
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-item:hover {
    cursor: pointer;
    transform: scale(1.08);
}

@media (max-width: 991px) {
    .form-group {
        margin-bottom: 10px !important; 
    }
    
}

@media (max-width: 500px) {
    .gallery-image {
        width: 250px; 
    }
}