code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* General Styles */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #71797E; */
  background-color: rgb(254, 254, 254);
  color: black;
}

/* Layout with Flexbox */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Use viewport height to ensure full height */
}

.App {
  /* Allows the app container to fill the available space */
  /* flex-grow: 1; */
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensures it takes at least the full viewport height */
}

/* Footer Styling */

/* position: absolute;  */
/* position: relative;  */
/* position: fixed; */
/* padding-top: 30px;
  padding-bottom: 30px; */
/* bottom: 0; 
  width: 100%;
  height: 2.5rem;  */
/* .footer {} */

/* Footer styles */
/* .footer {
  margin-top: auto;
  color: gray;
} */

.footer {
  margin-top: auto; 
  color: gray; 
  width: 100%; /* Ensure it spans the full width */
  padding: 10px 0; /* Add some padding to the footer */
  text-align: center; /* Center footer content */
}

footer p {
  font-size: 0.9rem;
  padding-top: 30px; 
}

.social-media {
  display: flex;
  justify-content: center;
  /* Center icons horizontally */
  gap: 25px;
  /* Spacing between icons */
  /* margin: 20px 0 50px 0;  */
  margin: 30px;
}

.social-icon {
  font-size: 1.75rem;
  /* Icon size */
  color: #333;
  /* Icon color */
}

.social-icon:hover {
  color: #282c34;
  /* Icon color on hover */
  cursor: pointer;
  transform: scale(1.2);
}

.btn-coffee {
  border: none; 
  border-radius: 25px; 
  padding: 10px 25px; 
}

.btn-coffee:hover {
  cursor: pointer;
  color: black;
  background-color: rgba(208, 236, 246);
  transform: scale(1.1); 
}

.bmc-link {
  /* color: #555;  */
  color: black;
  text-decoration: none;
}

/* Navigation, Header, and Other Components */
.App-header {
  /* Adjusted the min-height as per your layout needs */
  min-height: calc(100vh - 60px);
  /* Example: Adjust 60px to account for other elements like header/nav */
  /* background-color: #282c34;
  color: white; */
  font-size: calc(10px + 2vmin);
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

/* Container and Content Styling */
/* .container {
  padding: 20px 0; 
  flex: 1;
} */

.img-container {
  margin-bottom: 25px;
}

.avatar {
  width: 15%;
  border-radius: 50%;
}

.avatar-tag {
  font-size: 1rem;
}

.intro-container {
  display: grid;
  place-items: center;
  width: 45%;
  /* Set container width to 50% of its parent */
  margin: 0 auto;
  /* Additional centering for horizontal */
  padding-top: 20px;
}

p {
  font-size: 1.25rem;
  /* padding-bottom: 10px;  */
}

.link-btn {
  text-decoration: none;
  color: grey;
}

a:hover {
  font-weight: bold;
} 


