.search-results {
    padding: 20px 50px; 
}
.page-header {
    font-size: 20px; 
    margin: 10px; 
    display: flex; 
    justify-content: left; 
}

.search-term {
    color: black;
}

.results-container {
    margin: 20px; 
    padding: 10px; 
}
.result-item {
    display: flex; 
    flex-direction: row; 
    margin: 20px 0px; 
    transition: transform 450ms;
}

.result-item:hover {
    cursor: pointer;
    transform: scale(1.02);
}

.result-title-image {
    width: 150px; 
    height: 250px; 
    object-fit: cover;
    border-radius: 8px; 
}

.result-item-right {
    margin-left: 10px; 
    text-align: left;
}

.result-item-title {
    font-weight: 600; 
}