
.account-form-wrapper {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    padding: 20px; 
}

.account-form-container {
    display: flex; 
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    margin: 10px 0px; 
    /* padding: 30px 20px 20px 20px;  */
    padding: 20px; 
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.account-form {
    flex-basis: 50%;
    width: 100%;
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 8px;
}

.welcome-message {
    display: flex; 
    align-items: center;
    justify-content: center; 
    text-align: center; 
    color: grey;
    margin-bottom: 20px;
}

.response-message {
    text-align: center;
    color: green;
    margin-bottom: 20px;
}

/* .username-form, .email-form, .password-form {
    display: flex; 
    flex-direction: row; 
    align-items: center;
    justify-content: left; 
    width: 100%; 
} */

.form-group-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;
    align-items: center; 
}


/* .form-group {

    margin-bottom: 10px; 
    padding-right: none !important; 
    margin-right: none !important;
} */

.form-group {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-right: 20px;
    margin-bottom: none !important;
    text-align: left;
}

/* .form-label {
    margin-top: 5px; 

    vertical-align: top;
    white-space: nowrap;
} */

.form-label {
    margin-right: 10px; 
    margin-bottom: 0 !important;
    white-space: nowrap;
}

/* .form-control {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    flex-grow: 1; 
    padding: 10px 15px !important;
} */

.form-control {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 0 !important; 
}

.form-input {
    border: none !important;
    /* padding-bottom: none; */
    padding: 5px; 
    border-radius: 8px; 
}

.form-input:hover, .form-input:focus {
    border: 1px solid #ddd !important;
    border-radius: 8px; 
    padding-bottom: none;
}

.password-container {
    display: flex; 
    /* flex-direction: column; 
    justify-content: center;
    align-items: center; 
    width: 100%; */
    display: flex;
    align-items: center;
}

.password-group {
    margin-bottom: 20px !important;
}


.profile-container {
    display: flex;
    flex-direction: column; 
    /* flex-direction: row;   */
    justify-content: top;
    align-items: center;
    padding: 30px;
    background-color: white;
}

.photo-container {
    margin: 10px 0px; 
    display: flex; 
    flex-direction: column;
    align-items: center;
}

.profile-photo-icon {
    font-size: 5rem; 
    color: #888;
    transition: transform 0.3s ease;
}

.profile-photo-icon:hover {
    /* transform: scale(1.08); */
}

.profile-photo {
    width: 200px; 
    border-radius: 50%;
}

.profile-form {
    /* flex-basis: 50%; */
    width: 100%;
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.1); */
}

.btn-save-profile {
    margin: 0px 20px 20px 20px; 
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    transition: transform 0.3s ease;
    /* background-color: #333;
    color: #fff; */
    background-color: black;
    color: white;
}

.btn-save-profile:hover {
    cursor: pointer;
    font-weight: 600;
    background-color: rgba(246, 247, 249);
    color: black;
    /* background-color: rgba(208, 236, 246);
    color: black; */
    /* color: white;
    background-color: #333; */
    transform: scale(1.05);
}


.btn-profile {
    padding: 8px 20px;
    margin: 10px 0px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    align-items: center;
    font-size: 15px;
    transition: transform 0.3s ease;
    /* background-color: #333;
    color: #fff; */
    background-color: rgba(246, 247, 249);
    color: black;
}

.btn-profile:hover {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    background-color: #333;
    color: #fff;
    /* background-color: rgba(208, 236, 246);
    color: black; */
    /* color: white;
    background-color: #333; */
    transform: scale(1.05);
}

.btn-account {
    padding: 8px 20px;
    margin: 10px 0px;
    border: none;
    border-radius: 25px;
    white-space: nowrap;
    align-items: center;
    font-size: 15px;
    /* background-color: rgba(208, 236, 246); */
    background-color: rgba(246, 247, 249);
    color: black;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.btn-account:hover {
    background-color: #333;
    color: #fff;
    transform: scale(1.05);
}

.btn-account:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.btn-admin {
    padding: 8px 20px;
    margin: 10px;
    border: none;
    border-radius: 25px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    align-items: center;
    font-size: 15px;
}

.btn-admin:hover {
    cursor: pointer;
    /* color: white;
    background-color: #333; */
    border: none; 
    font-size: 16px;
    font-weight: 500;
    color: black;
    background-color: rgba(208, 236, 246);
}

.error-message {
    margin: 10px; 
}
.list-container {
    margin: 20px; 
}


@media (max-width: 991px) {
/* 

    .username-container, .email-container {
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center; 
        width: 100%; 
    }
    
    .password-form {
        display: flex; 
        flex-direction: column; 
    }
    .password-container {
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: center; 
        width: 100%;
    } */

    .account-form-wrapper {
        min-width: 300px; 
    }

    .form-label, .form-control {
        text-align: center; 
    }

    .account-form {
        padding: 10px 20px;
        display: flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center; 
    }

    .form-group-container {
        display: flex; 
        flex-direction: column;
        justify-content: center;
        align-items: center; 
        width: 100%; 
    }

    .username-group, .email-group, .password-group {
        display: flex; 
        flex-direction: column; 
        justify-content: center;
        align-items: left; 

    }

    .form-group {
        margin-right: 0;
        margin-bottom: 0 !important;
        width: 100%;
    }

}