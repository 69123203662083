.event-tabs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 20px;
}

.event-tabs {
    display: flex;
    /* justify-content: center; */
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
}

.event-tabs button {
    /* flex: 1; */
    padding: 5px 10px;
    margin: 0 10px;
    border: none;
    border-radius: 25px;
    background-color: #f0f0f0;
    cursor: pointer;
    text-wrap: nowrap;
}

.event-tabs button.active {
    background-color: #007bff;
    color: white;
}

.events-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    grid-auto-flow: column;
    grid-gap: 20px;
    padding: 10px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
}

.event-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    overflow: hidden;
}

.event-card:hover {
    cursor: pointer;
    font-weight: 600;
    /* transform: translateY(-5px); */
    transform: scale(1.05);

}

.event-image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
}

.event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.event-details {
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.event-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.event-title {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.event-name-cn {
    text-wrap: nowrap;
}

.event-name-cn,
.event.name-en {
    font-size: 1.2rem;
    font-weight: bold;
}

.event-upcoming-badge {
    background-color: #ff4081;
    color: white;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
}

.event-past-badge {
    background-color: #ddd;
    /* color: white; */
    /* color: grey; */
    color: black;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
}


.event-name-en {
    font-size: 1rem;
    color: #555;
}

.event-description {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 15px;
}

.event-time,
.event-location {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.event-icon {
    margin-right: 5px;
}

.event-tags {
    display: flex;
    gap: 10px;
}

.event-tag {
    background-color: rgb(208, 236, 246);
    color: #555;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.8rem;
}

@media (max-width: 698px) {
    .events-list {
        grid-auto-flow: row;
    }
}